@import 'reset-css';
@import 'settings';

a, a:visited {
    outline: none;
    text-decoration:none;
    color:$white;
}

a figure:active{
    transform:scale(0.95);
}

body{
    height: 100%; 
    display: grid;
    grid: auto 1fr auto / 15vw 1fr auto;
    background-color: $black30;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    color: $white;
    gap: $gap;
}

body > aside{
    grid-row: 1 / span 3;
    display:flex;
    flex-direction:column;
    gap:$gap;
    //border: solid yellow 1px;
}

body > aside figure:first-of-type{
    background-color:$black30;
    margin:0px;
}

body > aside > figure:first-of-type > div{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: $padding;
    //border: solid blue;
}

body > aside > figure:first-of-type > div > form{
    display:flex;
    border: solid green;
    height:20px;
    width:20px;
    padding:$padding;
    cursor: pointer;
}

body > aside > figure:first-of-type > div > form figure {
    border: solid red;
    cursor: pointer;
}

body > aside > figure:first-of-type img{
    width:100%;
}

body > aside:first-of-type{
    grid-column: 1;
    //border: solid brown;
}

body > aside:last-of-type{
    grid-column:4;
    //border: solid blue;
}

body > aside figure:first-of-type figcaption {
    font-size:x-large;
    //padding:$padding;
    //border: solid red;
}

body > aside nav:nth-of-type(1n){
    display:flex;
    justify-content: space-between;
    font-size:smaller;
    padding:$padding;
    background-color: $black30;
    align-items: center;
}

body > aside nav img, body > aside nav figure{
    padding:$padding;
    cursor: pointer;
}

body > aside nav img:hover, body > aside nav figure:hover,{
    background-color:$transparent;
    border-radius:$border-radius;
    padding:$padding;
}

body > aside nav img:hover:after, body > aside nav figure:hover:after,{
    position:absolute;
    left: 0;
    top: 25px;
    background-color:$black30;
}

footer {
    grid-row: 3;
    grid-column: 2;
    display:flex;
    justify-content: space-around;
    //border: solid red;
}

body > header{
    display:flex;
    flex-direction: column;
    grid-row:1;
    display: flex;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $black30;
  border-radius: 20px;
}

button {
    border: none;
    font-weight: bold;
    padding: 10px;
    border-radius: $border-radius;
    background-color: $red;
    cursor: pointer;
    color:white;
    text-transform: none;
    -webkit-appearance: button;
}

button:active {
    outline:none;
    transform:scale(0.96);
}

button, input, optgroup, select, textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:focus {
    outline:none;
}

figcaption{
    color:$white;
}

form[name=form] div{
    display:flex;
    gap:10px;
    justify-content: space-around;
}

form[name=presentation_privacy], form[name=privacy], {
    width:30px;
    height:30px;
}

form[name=avatar] input[type=file],form[name=logo] input[type=file],form[name=logo2] input[type=file],form[name=workspace] input[type=file], form[name=boardspace] input[type=file]{
    display:none;
}

form[name=avatar] label,form[name=logo] label,form[name=logo2] label, form[name=workspace] label, form[name=boardspace] label {
    display:flex;
    align-self: center;
    cursor: pointer;
}

form[name=avatar] label[for=avatar_file]:after,form[name=logo] label[for=logo_file]:after,form[name=logo2] label[for=logo2_file]:after {
    content: url('../images/camera.svg');
    padding: $padding;
}

form[name=avatar]:hover label[for=avatar_file]:after,form[name=logo]:hover label[for=logo_file]:after,form[name=logo2]:hover label[for=logo2_file]:after {
    background-color:$transparent;
    border-radius: $border-radius;
}

form[name=lang] div > div{
    overflow: hidden;
    background:  url("../images/language.svg") no-repeat center;
}

form[name=lang] select {
    width:30px;
    height:30px;
    border: none;
    box-shadow: none;
    background: transparent;
    background-image: none;
    -webkit-appearance: none;
    color:transparent;
    cursor:pointer;
}

form[name=lang] select:hover{
    background-color:$transparent;
    padding:$padding;
    border-radius: $border-radius;
}

form[name=lang] select:focus {
    outline: none;
}

form[name=searchy] input {
    margin:$margin;
    background:  url("../images/search.svg") no-repeat left;
    background-position: 5px;
    background-color:$black40;
    padding-left: 30px;
}

html {
    width: 100%;
    height: 100%;
    height: 100vh;
    height: calc( var(--vh, 1vh) * 100 );
    overflow: hidden;
}

img:active {                                                   
    transform:scale(0.96);                                                               
}   

input {
    background-color:$black40;
    border: 1px solid $black40;
    border-radius: $border-radius;
    color:white;
    padding:10px;
}

input:focus {
    outline:none;
    border: 1px solid $blue;
    box-shadow: 0 0 10px $blue;
}

input[type=color]{
    width:30px;
    height:30px;
    border:none;
    background-color:transparent;
    cursor:pointer;
    padding:$padding;
}

input[type=color]:hover{
    background-color:$transparent;
    border-radius:$border-radius;
    padding:$padding;
}

input[type=submit] {
    background-color: $red;
    font-weight: bold;
}

input[type=submit]:active {
    transform:scale(0.96);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

label{
    color:$white;
}

main{
    grid-row: 2;
    grid-column: 2;
    display: grid;
    transition: 2s;
    grid-template-rows: 1fr 1fr 0fr;
    grid-template-columns: 1fr 1fr 0fr;
    //grid: 1fr 1fr / 1fr 1fr;
    gap: $gap;
    //border: solid yellow;
}

main > section{
    //border-radius: $border-radius;
    background-color: $black30;
    //border: solid yellow;
}

main > section > header{
    //border: solid green;
    padding:$padding;
    display:flex;
    justify-content:space-around;
    align-items:center;
}

main section:nth-of-type(1){
    grid-row: 1;
    grid-column: 1;
}

main section:nth-of-type(2){
    grid-row: 1;
    grid-column: 2;
}

main section:nth-of-type(3){
    grid-row: 2;
    grid-column: 1;
}

main section:last-of-type{
    grid-row: 2;
    grid-column: 2;
}

p{
    color: $white;
    margin: 3px;
}

span{
    color:$white;
}

textarea{
    resize: none;
    background-color: $black40;
    color: $white;
    border: 0 none;
}

.copyright {
    font-size: small;
    color: rgb(70,70,70);
}

.alert-success{
    color: $green;
}

#sidebloc{
    display:flex;
    flex-direction: column;
    gap: $gap;
    height: 50vh;
    flex:1;
    color: $white;
}

#sidebloc article{
    //border: solid red;
    padding:$padding;
    //border-radius:$border-radius 0px 0px $border-radius;
    background-color:$black30;
}

#sidebloc article:last-of-type{
    flex:1;
}

#sidebloc article p{
    display:flex;
    justify-content: space-between;
    color: $white;
}

//.mini_avatar img {
//    transform:scale(0.95);
//    border-radius:$border-radius;
//    border: solid red;
//}

.search-result {
    display: flex;
    gap:$gap;
    flex-direction: column;
    position: absolute;
    z-index:2;
    background-color: $black50light;
    border-radius: 0px 0px $border-radius $border-radius;
}

.search-result figure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:$gap*5;
    padding: $padding;
}

.search-result a:hover{
    background-color: $transparent;
}

.search-result a:last-of-type:hover{
    border-radius: 0px 0px $border-radius $border-radius;
}

.switch {
    position: relative;
    display: flex;
    //width: 20px;
    //height:20px;
    //  margin-right:$margin;
    //border: solid green;
}

.switch figure{
    position: absolute;
}

//.switch input {
//    opacity: 0;
//    width: 0;
//    height: 0;
//}

#privacy_public{
    opacity:0;
    //margin-right:100px;
    width:0;
    height: 0;
    //border: solid red;
}

//.slider {
//  position: absolute;
//  cursor: pointer;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//  background-color: #ccc;
//  -webkit-transition: .4s;
//  transition: .4s;
//}

//.slider:before {
//  position: absolute;
//  content: "";
//  height: 12px;
//  width: 12px;
//  left: 4px;
//  bottom: 4px;
//  background-color: white;
//  -webkit-transition: .4s;
//  transition: .4s;
//}

//input:checked + .slider {
//  background-color: $red;
//}

//input:focus + .slider {
//  box-shadow: 0 0 1px $red;
//}

//input:checked + .slider:before {
//  -webkit-transform: translateX(16px);
//  -ms-transform: translateX(16px);
//  transform: translateX(16px);
//}

//.slider.round {
//  border-radius: 34px;
//}

//.slider.round:before {
//  border-radius: 50%;
//}

.link{
    font-size:small;
}

.eye-closed{
    content: url('../images/eye_closed.svg');
}

.eye-opened{
    content: url('../images/eye_opened.svg');
}

@media screen and (max-width: 600px) {
    body {
	grid-template-columns: 15vw 1fr 12vw;
	grid-template-rows: 1fr 1fr 1fr;
    }
}

.flex-table{
    display:flex;justify-content:space-between;
}

.flex-table > div{
    display:flex;
    flex-direction:column;
    gap:5px;
}

.flex-table > div > div{
    display:flex;
    justify-content:flex-end;
    height:20px;
    align-items:center;
    gap: $gap;
}

.flex-table > div > figure{
    height:20px;
    display: flex;
    position: relative;
}

.flex-table > div > figure:hover:after{
    content: attr(data-info);
    //content: attr(data-invitees);
    position: absolute;
    left: 25px;
    top: 0px;
    z-index:1;
    background-color: $black50light;
    padding: $padding;
    border-radius: $border-radius;
}

.blue{
    text-shadow: -1px 1px 3px $blue, 1px -1px 3px $blue;
}

.red{
    text-shadow: -1px 1px 3px $red, 1px -1px 3px $red;
}

.green{
    text-shadow: -1px 1px 3px $green, 1px -1px 3px $green;
}



#firstsubb form{
    //border: solid red;
    display: flex;
    flex-direction:column;
    gap: $gap;
}


#subscription section:first-of-type{
    display:flex;
    flex-direction:row;
    align-items:center;
}

#card-element{
    appearance: none;
    display: block;
    padding: $padding*2;
    background-color: $black40;
    border-radius : $border-radius;
}

#payment{
    display:flex;
    flex-direction: column;
    border: solid pink;
}

#payment div{
    color:gray;
    font-size:smaller;
}

/*STRIPE*/

.loading {
  width: 30px;
  height: 30px;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.container {
  width: 100%;
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-1 {
  flex: 1 1 0%;
}

.outline-none {
  outline: 0;
}

.focus\:outline-none:focus {
  outline: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.inset-x-0 {
  right: 0;
  left: 0;
}

.bottom-0 {
  bottom: 0;
}


.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px $red;
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px $red;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.hidden {
    display: none;
}

.duration-150 {
  transition-duration: 150ms;
}

#firstsubb{
    display:flex;
    flex-direction:column;
    //flex-wrap: wrap; 
    //justify-content: flex-start;
    //border: solid yellow;
}

#firstsubb > div:nth-child(3n){
    padding: $padding;
}

#subscription nav{
    display: flex;
    justify-content: space-between;
}

#subscription nav button{
    border-radius: $border-radius;
    padding: $padding;
    background-color:transparent;
}

.free, .basic, .premium{
    color: $black40;
}

#product{
    color: $white;
}

#loading{
    color:white;
}

.cancelsub{
    color:white;
}

